import { Auth0Client } from '@auth0/auth0-spa-js';

// either with async/await
const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: `${window.location.origin}/authorization`,
    scope: 'openid profile email',
  },
});

export const loginAdminAuth0 = async () => {
  try {
    await auth0.loginWithRedirect({
      async openUrl(url) {
        window.location.replace(url);
      },
      // redirect_uri: `${window.location.origin}/authorization`,
      // redirectMethod: 'replace',
      // login_hint: 'ADMIN_DASHBOARD',
      authorizationParams: {
        login_hint: 'ADMIN_DASHBOARD',
        redirectMethod: 'replace',
      },
    });
    const user = await auth0.getUser();
    return user;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.error !== 'login_required') {
      return false;
    }
  }
};

export const logoutAdminAuth0 = async (status = '') => {
  let redirectQuery = '';

  if (status === 'session-expired') {
    redirectQuery = '?authStatus=LoggedOutAfterSessionExpired';
  } else if (status === 'token-invalid') {
    redirectQuery = '?authStatus=TokenInvalid';
  }

  const res = await auth0.logout({
    logoutParams: {
      returnTo: `${window.location.origin}/login${redirectQuery}`,
    },
  });

  return res;
};

export const getAccessTokenAuth0 = async () => {
  try {
    const token = await auth0.getTokenSilently();
    return token;
  } catch (error) {
    return false;
  }
};

export const isAuthenticatedAuth0 = async () => {
  try {
    const isAuthenticated = await auth0.isAuthenticated();
    return isAuthenticated;
  } catch (error) {
    return false;
  }
};
