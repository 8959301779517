interface IProps {
  propertyHeader: string;
  usersHeader: string;
}

const AnalyticsTableHeader = ({ propertyHeader = '', usersHeader = '' }: IProps) => {
  return (
    <thead className="table-dark">
      <tr>
        <th style={{ width: '50%' }} className="text-start">
          {propertyHeader}
        </th>
        <th style={{ width: '50%' }} className="text-end">
          {usersHeader}
        </th>
      </tr>
    </thead>
  );
};

export default AnalyticsTableHeader;
