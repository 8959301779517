import { lazyWithRetry } from 'utils/lazyWithRetry';

export const CalendarPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "calendar" */
      './pages/calendar-pages/CalendarPage'
    ),
);

export const EditMessagePageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "edit-message" */
      './pages/messages-pages/EditMessagePage'
    ),
);

export const CreateMessagePageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "create-message" */
      './pages/messages-pages/CreateMessagePage'
    ),
);

export const CreateTaskPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "create-task" */
      './pages/tasks-pages/CreateTaskPage'
    ),
);

export const EditTaskPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "edit-task" */
      './pages/tasks-pages/EditTaskPage'
    ),
);

export const TaskDetailsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "task-details" */
      './pages/tasks-pages/TaskDetailsPage'
    ),
);

export const DocumentLibraryPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "document-library" */
      './pages/document-library-pages/DocumentLibraryPage'
    ),
);

export const CalendarEventDetailsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "calendar-event" */
      './pages/calendar-pages/CalendarEventDetailsPage'
    ),
);

export const EditCalendarEventPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "edit-calendar" */
      './pages/calendar-pages/EditCalendarEventPage'
    ),
);

export const MessageDetailsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "message-details" */
      './pages/messages-pages/MessageDetailsPage'
    ),
);

export const SidebarPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "sidebar" */
      './pages/sidebar-pages/SidebarPage'
    ),
);

export const MessagesPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "messages" */
      './pages/messages-pages/MessagesPage'
    ),
);

export const TasksPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "tasks" */
      './pages/tasks-pages/TasksPage'
    ),
);

export const CreateCalendarEventPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "create-calendar-event" */
      './pages/calendar-pages/CreateCalendarEventPage'
    ),
);

export const LoginPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "login" */
      './pages/auth-pages/LoginPage'
    ),
);

export const BroadcastPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "broadcast" */
      './pages/broadcast-pages/BroadcastPage'
    ),
);

export const VideoGalleryPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "video-gallery" */
      './pages/video-pages/VideoGallery'
    ),
);

export const AddVideoPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "add-video" */
      './pages/video-pages/AddVideoPage'
    ),
);

export const EditVideoPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "edit-video" */
      './pages/video-pages/EditVideoPage'
    ),
);

export const PartnersPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "partners" */
      './pages/partners-pages/PartnersPage'
    ),
);

export const CreatePartnerPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "create-partner" */
      './pages/partners-pages/CreatePartnerPage'
    ),
);

export const PartnerDetailsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "partner-details" */
      './pages/partners-pages/PartnerDetailsPage'
    ),
);

export const EditPartnerPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "edit-partner" */
      './pages/partners-pages/EditPartnerPage'
    ),
);

export const UsersPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "users" */
      './pages/user-pages/UsersPage'
    ),
);

export const MembershipAgreementsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "membership-agreements" */
      './pages/membership-agreement-pages/MembershipAgreementsPage'
    ),
);

export const NotFoundPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "not-found" */
      './pages/utils-pages/NotFoundPage'
    ),
);

export const AuthorizationPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "authorization" */
      './pages/auth-pages/AuthorizationPage'
    ),
);

export const AnalyticsPageLazy = lazyWithRetry(
  () => import(
      /* webpackChunkName: "analytics" */
      './pages/analytics-pages/AnalyticsPage'
    ),
);
