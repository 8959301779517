import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'index';
import {
 Col, Container, Row, Spinner, Table,
} from 'react-bootstrap';
import Select from 'react-select';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DefaultLayout from 'layout/DefaultLayout';
import { getAnalyticsUsersAction } from 'store/actions/usersActions';
import TableSkeleton from 'components/UtilsComponents/TableSkeleton';
import AnalyticsTableHeader from 'components/AnalyticsComponents/AnalyticsTableHeader';
import {
  IAnalyticsData,
  IAnalyticsGeo,
  IAnalyticsScreens,
  IAnalyticsUsers,
  ISelectValueObject,
} from 'lib/interfaces';
import SummaryComponent from 'components/AnalyticsComponents/SummaryComponent';
import { ANALYTICS_PERIOD } from 'utils/constants';
import {
  addSeparators,
  calculateAnalyticsPeriod,
  convertSecondsToHms,
  convertToUSNumberFormat,
  returnChartData,
  sortAnalyticDataByDate,
  sortAnalyticDataByUsers,
} from 'utils/helpers';
import moment from 'moment';

const AnalyticsPage = () => {
  const dispatch = useAppDispatch();
  const [loaderActive, setLoaderActive] = useState(false);
  const [analyticsPeriod, setAnalyticsPeriod] = useState<null | ISelectValueObject>({
    label: 'Last 7 Days',
    value: '7',
    id: '7',
  });
  const [analyticsDates, setAnalyticsDates] = useState({
    startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'd').format('YYYY-MM-DD'),
  });
  const [analyticsData, setAnalyticsData] = useState({
    activeUsers: [] as Array<IAnalyticsUsers>,
    newUsers: [] as Array<IAnalyticsUsers>,
    userEngagementDurationInSeconds: [] as Array<IAnalyticsUsers>,
    geoUsers: [] as Array<IAnalyticsGeo>,
    screenValues: [] as Array<IAnalyticsScreens>,
  });

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
  }, []);

  useEffect(() => {
    setLoaderActive(true);
    dispatch(
      getAnalyticsUsersAction(analyticsDates.startDate, analyticsDates.endDate),
    ).then((res: IAnalyticsData) => {
      if (res) {
        setAnalyticsData((prevState) => ({
          ...prevState,
          activeUsers: sortAnalyticDataByDate(res?.activeUsers),
          newUsers: sortAnalyticDataByDate(res?.newUsers),
          geoUsers: res?.geoUsers,
          screenValues: res?.screenValues,
          userEngagementDurationInSeconds: res?.userEngagementDurationInSeconds,
        }));
      }
      setLoaderActive(false);
    });
  }, [dispatch, analyticsDates]);

  const handleAnalyticsDateChange = (option: ISelectValueObject) => {
    const { startDate = '' } = calculateAnalyticsPeriod(option.value);
    setAnalyticsDates((prevState) => ({
      ...prevState,
      startDate,
    }));
  };

  const {
    activeUsers = [],
    newUsers = [],
    geoUsers = [],
    screenValues = [],
    userEngagementDurationInSeconds = [],
  } = analyticsData;

  const parsedGeoData = useCallback(
    (type: string) => {
      const listOfEntities = geoUsers.map((geo) => type === 'country' ? geo.country : geo.city);
      const uniqueEntities = Array.from(new Set(listOfEntities));

      return uniqueEntities.map((entity) => {
        const data = geoUsers.filter((countryUsers) => type === 'country'
            ? countryUsers.country === entity
            : countryUsers.city === entity);
        const users = data.map((countryUser) => countryUser.users);
        const sum = users?.reduce((a, b) => a + b, 0);
        const entityByCountry = data.find((countryUser) => countryUser.city !== '(not set)' ? countryUser.country : '');
        return {
          entity,
          users: sum,
          country: entityByCountry?.country,
        };
      });
    },
    [geoUsers],
  );

  const calculateCardNumbers = useCallback(
    (arg: string) => {
      switch (arg) {
        case 'activeUsers':
          return convertToUSNumberFormat(activeUsers.reduce((a, b) => a + b?.users, 0));
        case 'newUsers':
          return convertToUSNumberFormat(newUsers.reduce((a, b) => a + b?.users, 0));
        case 'engagementTime':
          return convertSecondsToHms(
            userEngagementDurationInSeconds.reduce((a, b) => a + b?.users, 0),
          );
        default:
          return 0;
      }
    },
    [activeUsers, newUsers, userEngagementDurationInSeconds],
  );

  return (
    <DefaultLayout>
      <Container fluid>
        <Row>
          <Col lg={12} className="pb-5">
            <h1>Analytics</h1>
          </Col>
          <Col lg={12} className="mb-5 ">
            <Row className="mb-3">
              <Col lg={6}>
                <h2>Summary</h2>
              </Col>
              <Col lg={6}>
                <div className="analytics-select-wrapper">
                  <Select
                    placeholder="Choose period"
                    options={ANALYTICS_PERIOD}
                    isDisabled={loaderActive}
                    isOptionDisabled={(option) => option.id === analyticsPeriod?.id}
                    value={analyticsPeriod}
                    onChange={(option) => {
                      setAnalyticsPeriod(option as ISelectValueObject);
                      handleAnalyticsDateChange(option as ISelectValueObject);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Container fluid>
              <div className="analytics-summary-wrapper text-center shadow-sm bg-white p-4 rounded">
                <SummaryComponent
                  loaderActive={loaderActive}
                  cardText="Number of active users"
                  cardNumber={calculateCardNumbers('activeUsers')}
                />
                <SummaryComponent
                  loaderActive={loaderActive}
                  cardText="Number of new users"
                  cardNumber={calculateCardNumbers('newUsers')}
                />
                <SummaryComponent
                  loaderActive={loaderActive}
                  cardText="Total user engagement time (hh:mm:ss)"
                  cardNumber={calculateCardNumbers('engagementTime')}
                />
                <SummaryComponent
                  loaderActive={loaderActive}
                  cardText={`Users from most visited country (${
                    parsedGeoData('country')[0]?.country || ''
                  })`}
                  cardNumber={convertToUSNumberFormat(parsedGeoData('country')[0]?.users)}
                />
              </div>
            </Container>
          </Col>
          <Col lg={12} className="mb-5">
            <h2 className="mb-3">Active Users</h2>
            <Container fluid>
              <Row className="text-center shadow-sm bg-white p-4 rounded">
                <Col lg={8} className="border rounded px-1 pt-5">
                  {loaderActive ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Spinner animation="grow" />
                    </div>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={returnChartData(activeUsers, 'Active Users')}
                    />
                  )}
                </Col>
                <Col lg={4}>
                  <div className="analytics-table-wrapper">
                    <Table bordered className="table table-sm" striped>
                      <AnalyticsTableHeader propertyHeader="Date" usersHeader="Users" />
                      {loaderActive ? (
                        <TableSkeleton numberOfHeaders={2} numberOfRows={7} />
                      ) : (
                        activeUsers
                        && activeUsers?.length > 0 && (
                          <tbody>
                            {activeUsers?.map((user) => {
                              return (
                                <tr key={user?.date}>
                                  <td className="text-start">
                                    {addSeparators(user?.date, [4, 7])}
                                  </td>
                                  <td className="text-end">
                                    {convertToUSNumberFormat(user?.users)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )
                      )}
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={12} className="mb-5">
            <h2 className="mb-3">New Users</h2>
            <Container fluid>
              <Row className="text-center shadow-sm bg-white p-4 rounded">
                <Col lg={8} className="border rounded px-1 pt-5">
                  {loaderActive ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Spinner animation="grow" />
                    </div>
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={returnChartData(newUsers, 'New Users')}
                    />
                  )}
                </Col>
                <Col lg={4}>
                  <div className="analytics-table-wrapper">
                    <Table bordered className="table table-sm" striped>
                      <AnalyticsTableHeader propertyHeader="Date" usersHeader="Users" />
                      {loaderActive ? (
                        <TableSkeleton numberOfHeaders={2} numberOfRows={7} />
                      ) : (
                        newUsers
                        && newUsers?.length > 0 && (
                          <tbody>
                            {newUsers?.map((user) => {
                              return (
                                <tr key={user?.date}>
                                  <td className="text-start">
                                    {addSeparators(user?.date, [4, 7])}
                                  </td>
                                  <td className="text-end">
                                    {convertToUSNumberFormat(user?.users)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )
                      )}
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={12} className="mb-5">
            <h2 className="mb-3">Top Content</h2>
            <Container fluid>
              <Row className="text-center shadow-sm bg-white py-5 px-4 rounded">
                <div className="analytics-table-wrapper">
                  <Table bordered className="table" striped>
                    <AnalyticsTableHeader propertyHeader="Screen" usersHeader="Views" />
                    {loaderActive ? (
                      <TableSkeleton numberOfHeaders={2} numberOfRows={7} />
                    ) : (
                      screenValues
                      && screenValues?.length > 0 && (
                        <tbody>
                          {screenValues?.map((user) => {
                            return (
                              <tr key={user?.screen}>
                                <td className="text-start">{user?.screen}</td>
                                <td className="text-end">
                                  {convertToUSNumberFormat(user?.views)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )
                    )}
                  </Table>
                </div>
              </Row>
            </Container>
          </Col>
          <Col lg={12} className="mb-5">
            <h2 className="mb-3">Users by Country</h2>
            <Container fluid>
              <Row className="text-center shadow-sm bg-white py-5 px-4 rounded">
                <div className="analytics-table-wrapper">
                  <Table bordered className="table" striped>
                    <AnalyticsTableHeader propertyHeader="Country" usersHeader="Users" />
                    {loaderActive ? (
                      <TableSkeleton numberOfHeaders={2} numberOfRows={7} />
                    ) : (
                      geoUsers
                      && geoUsers?.length > 0 && (
                        <tbody>
                          {sortAnalyticDataByUsers(parsedGeoData('country'))?.map(
                            (user) => {
                              return (
                                <tr key={user?.entity}>
                                  <td className="text-start">{user?.entity}</td>
                                  <td className="text-end">
                                    {convertToUSNumberFormat(user?.users)}
                                  </td>
                                </tr>
                              );
                            },
                          )}
                        </tbody>
                      )
                    )}
                  </Table>
                </div>
              </Row>
            </Container>
          </Col>
          <Col lg={12} className="mb-5">
            <h2 className="mb-3">Users by City</h2>
            <Container fluid>
              <Row className="text-center shadow-sm bg-white py-5 px-4 rounded">
                <div className="analytics-table-wrapper">
                  <Table bordered className="table" striped>
                    <AnalyticsTableHeader propertyHeader="City" usersHeader="Users" />
                    {loaderActive ? (
                      <TableSkeleton numberOfHeaders={2} numberOfRows={7} />
                    ) : (
                      geoUsers
                      && geoUsers?.length > 0 && (
                        <tbody>
                          {sortAnalyticDataByUsers(parsedGeoData('city'))?.map((user) => {
                            return (
                              <tr key={user?.entity}>
                                <td className="text-start">
                                  {`${user?.entity} - ${user?.country || 'n/a'}`}
                                </td>
                                <td className="text-end">
                                  {convertToUSNumberFormat(user?.users)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )
                    )}
                  </Table>
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default AnalyticsPage;
