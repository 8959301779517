export const ALERT_MESSAGES = {
  ADDED_SUCCESSFULLY: 'Added successfully.',
  BODY_MUST_BE_LONGER_THAN_3_CHARACTERS: 'Body must be longer than 3 characters.',
  BODY_MUST_BE_SHORTER_THAN_N_CHARACTERS:
    'Body must be shorter than or equal to 1500 characters.',
  DELETED_SUCCESSFULLY: 'Deleted successfully.',
  DELETED_USER_SUCCESSFULLY:
    'User will be deleted from LCA Hub. Before registering again with the same email, please wait until the next OmegaFi synchronisation event.',
  DESCRIPTION_MUST_BE_LONGER_THAN_3_CHARACTERS:
    'Description must be longer than 3 characters.',
  DESCRIPTION_MUST_BE_SHORTER_THAN_500_CHARACTERS:
    'Description must be shorter than or equal to 500 characters.',
  EMPTY_BROADCAST_ACTIONS: 'Select at least one action.',
  EMPTY_ENTITIES_NOT_ALLOWED: 'Select at least one member.',
  EMPTY_OFFICERS_NOT_ALLOWED: 'Select at least one officer group.',
  EMPTY_FIELDS_NOT_ALLOWED: "Fields can't be empty.",
  EMPTY_FIELD_NOT_ALLOWED: "Field can't be empty.",
  EVENT_DATE_NOT_SELECTED: 'Event must have a date.',
  EVENT_MUST_BE_LONGER_THAN_3_CHARACTERS: 'Event must be longer than 3 characters.',
  FILE_SIZE_TOO_BIG: 'File is bigger than 30MB, please upload another one.',
  FILE_TYPE_NOT_SUPPORTED:
    'This file type is not supported. File can only be .pdf, .docx, .txt, .png, .jpeg, .pptx, .xlsx or .zip.',
  IMAGE_SIZE_TOO_BIG: 'Image is bigger than 10MB, please upload another one.',
  IMAGE_TYPE_NOT_SUPPORTED:
    'This file type is not supported. Image can only be .jpg or .png',
  FILE_UPLOAD_FAILED: 'An error occurred while uploading files',
  REORDER_SUCCESSFUL: 'Reordered successfully.',
  SELECT_A_FILE_INFO: 'Please select file.',
  SELECT_A_IMAGE_INFO: 'Please select image.',
  SELECT_A_VIDEO_INFO: 'Please select video.',
  SELECT_TIERS: 'Select Tiers.',
  SESSION_EXPIRED: 'Your session has expired. Please log in again.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  SUBJECT_MUST_BE_LONGER_THAN_3_CHARACTERS: 'Subject must be longer than 3 characters.',
  SUCCESS_LOGIN_MESSAGE: 'Successfully logged in.',
  TOKEN_INVALID: "Couldn't get auth token.",
  UPDATED_SUCCESSFULLY: 'Updated successfully.',
  URL_FORMAT_INCORRECT:
    'URL format not correct. It should be in format of https://www.example.com.',
  LINK_FORMAT_INCORRECT:
    'Link format not correct. It should be in format of https://www.example.com or mailto:.',
  VIDEO_TYPE_NOT_SUPPORTED: 'This file type is not supported. Video can only be .mp4',
  VIDEO_TYPE_TOO_BIG: 'Video is bigger than 150MB, please upload another one.',
  NO_USER_FOUND: 'No user found.',
  TITLE_MUST_BE_LONGER_THAN_1_CHARACTER: 'Title must be longer than 1 character.',
  THIS_TITLE_IS_ALREADY_TAKEN_MESSAGE:
    'This title is already taken. Please chose another title.',
  TITLE_CAN_CONTAIN_LETTERS_AND_NUMBERS_ONLY:
    'Title can contain letters and numbers only.',
  REVOKED_SUCCESSFULLY: 'Membership agreement revoked successfully',
  TITLE_CAN_NOT_CONTAIN_SPECIAL_CHARACTERS:
    'Title can not contain special characters (\\ / : ? * " < > , |).',
};
