import React from 'react';
import { useLocation } from 'react-router-dom';
import {
 Card, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import logo from '../../assets/img/logo-big.png';
import logoMobile from '../../assets/img/logo-white.png';
import DefaultLayout from 'layout/DefaultLayout';

const LoadingPage = () => {
  const location = useLocation();

  if (location.pathname === '/login') {
    return (
      <div className="login-page">
        <Container className="pt-5 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="card-background">
                <Card.Body className="px-lg-5 py-lg-5">
                  <picture>
                    <source media="(min-width: 992px )" srcSet={logo} />
                    <source media="(max-width: 992px )" srcSet={logoMobile} />
                    <img alt="LCA logo" className="login-logo" src={logo} />
                  </picture>
                  <div className="text-center text-muted my-3 card-body">Loading...</div>
                  <div className="text-center text-muted my-3 card-body">
                    <Spinner animation="border" role="status" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <DefaultLayout>
        <Container fluid>
          <Row>
            <Col lg={12} className="pb-5">
              <h1>Loading...</h1>
            </Col>
          </Row>
        </Container>
      </DefaultLayout>
    );
  }
};

export default LoadingPage;
