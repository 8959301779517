import React, { useEffect } from 'react';
import {
 Card, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/img/logo-big.png';
import logoMobile from '../../assets/img/logo-white.png';
import {
  getAdminAuthAction,
  logOutUserAuthAction,
} from '../../store/actions/authActions';
import { getAccessTokenAuth0 } from '../../auth0/auth0Auth';
import { useAppDispatch } from 'index';

const AuthorizationPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const queries = new URLSearchParams(window.location.search);
    const code = queries.get('code') || '';
    const state = queries.get('state') || '';

    if (code && state) {
      getAccessTokenAuth0().then((token) => {
        if (token) {
          dispatch(getAdminAuthAction()).then((res: boolean) => {
            if (res) {
              history.push('/');
            } else {
              history.push('/login');
            }
          });
        } else {
          dispatch(logOutUserAuthAction('token-invalid'));
        }
      });
    } else {
      history.push('/login');
    }
  }, [dispatch, history]);

  return (
    <div className="login-page">
      <Container className="pt-5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="card-background">
              <Card.Header className="bg-transparent">
                <div className="text-muted text-center my-2 card-body">
                  Welcome to the LCA HUB - Admin Dashboard
                </div>
              </Card.Header>
              <Card.Body className="px-lg-5 py-lg-5">
                <picture>
                  <source media="(min-width: 992px )" srcSet={logo} />
                  <source media="(max-width: 992px )" srcSet={logoMobile} />
                  <img alt="LCA logo" className="login-logo" src={logo} />
                </picture>
                {/* <img alt="LCA logo" className="login-logo" src={logo} /> */}
                <div className="text-center text-muted my-3 card-body">
                  Please wait...
                </div>
                <div className="text-center text-muted my-3 card-body">
                  <Spinner animation="border" role="status" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AuthorizationPage;
