import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

/**
 * Returns sorted array
 * @returns {Array} returns sorted array
 * @param {array} array - array for sorting
 * @param {property} property - property by which we sort array
 * @param {boolean} isDate - if we need to sort date return true, otherwise false
 * @param {boolean} sorting - sorting by asd order or desc
 */
export const sortByProperty = (
  array = [],
  property = '',
  isDate = false,
  sorting = false,
) => {
  if (property === 'entitiesLength') {
    return array.sort((a, b) => {
      if (sorting) {
        return a[property] < b[property] ? 1 : -1;
      } else {
        return a[property] > b[property] ? 1 : -1;
      }
    });
  } else if (!isDate) {
    return array.sort((a, b) => {
      if (sorting) {
        return a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1;
      } else {
        return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
      }
    });
  } else {
    return array.sort((a, b) => {
      const xc = a[property] ? moment(a[property]) : moment('1980-01-01T12:00:00.000Z');
      const yc = b[property] ? moment(b[property]) : moment('1980-01-01T12:00:00.000Z');
      if (sorting) {
        return yc.isAfter(xc) ? -1 : 1;
      } else {
        return yc.isBefore(xc) ? -1 : 1;
      }
    });
  }
};

export const sortDocumentsByProperty = (array = [], property = '', sorting = false) => {
  const arrayFolders = array.filter((a) => a.fileName === null);
  const arrayDocuments = array.filter((a) => a.fileName !== null);
  if (property === 'date') {
    const sortedFolders = arrayFolders.sort((a, b) => {
      const xc = a.updatedAt ? moment(a.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      const yc = b.updatedAt ? moment(b.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      if (sorting) {
        return yc.isAfter(xc) ? -1 : 1;
      } else {
        return yc.isBefore(xc) ? -1 : 1;
      }
    });
    const sortedDocuments = arrayDocuments.sort((a, b) => {
      const xc = a.updatedAt ? moment(a.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      const yc = b.updatedAt ? moment(b.updatedAt) : moment('1980-01-01T12:00:00.000Z');
      if (sorting) {
        return yc.isAfter(xc) ? -1 : 1;
      } else {
        return yc.isBefore(xc) ? -1 : 1;
      }
    });
    return [...sortedFolders, ...sortedDocuments];
  } else {
    const sortedFolders = arrayFolders.sort((a, b) => {
      if (sorting) {
        return a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1;
      } else {
        return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
      }
    });
    const sortedDocuments = arrayDocuments.sort((a, b) => {
      if (sorting) {
        return a[property].toLowerCase() < b[property].toLowerCase() ? 1 : -1;
      } else {
        return a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1;
      }
    });
    return [...sortedFolders, ...sortedDocuments];
  }
};

export const sanitizeHTMLString = (htmlString) => {
  return sanitizeHtml(htmlString, {
    allowedTags: [
      'h1',
      'h2',
      'blockquote',
      'hr',
      'li',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'bdi',
      'bdo',
      'br',
      'cite',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      's',
      'samp',
      'small',
      'strong',
      'sub',
      'sup',
      'u',
      'wbr',
      'iframe',
      'img',
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
      iframe: ['src'],
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { target: '_blank' }),
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    allowedSchemesByTag: {
      img: ['data', 'src', 'alt', 'http', 'https'],
    },
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  });
};

export const isValidURL = (url) => {
  // eslint-disable-next-line max-len
  const regexp = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return regexp.test(url.trim());
};

export const isValidTitle = (title) => {
  // eslint-disable-next-line no-useless-escape
  const regexp = /[:"\\*|<>\/?,]/;
  return regexp.test(title.trim());
};

export const isValidMailToLink = (url) => {
  return url.startsWith('mailto:');
};

export const convertMemberStatusLabelForUser = (status = '') => {
  if (status === 'Other') {
    return 'Non-Member';
  } else if (status === 'Initiate') {
    return 'Collegiate Brother';
  } else if (status === 'Alumnus') {
    return 'Alumni Brother';
  } else if (status === 'Suspended') {
    return 'Expelled';
  } else {
    return 'Associate Member';
  }
};

export const escapeHTML = (htmlCode) => {
  htmlCode = htmlCode.toString();
  return htmlCode
    .replace(/<[^>]*(>|$)|&nbsp;|&#34;|&#39;|&zwnj;|&raquo;|&laquo;|&gt;|\n/g, ' ')
    .replace(/&amp;/g, '&');
};

export const parseTierString = (tier = '') => {
  const parsedTier = tier.charAt(0).toUpperCase() + tier.slice(1).replace(/([^0-9])([0-9])/g, '$1 $2');
  return parsedTier;
};

// input: string,
// positions: number[] - index where "-" separator is needed,
export function addSeparators(input, positions) {
  const inputValue = input.split('');
  inputValue.forEach((_element, i) => {
    if (positions.includes(i)) inputValue.splice(i, 0, '-');
  });
  return inputValue.join('');
}

export const calculateAnalyticsPeriod = (numberOfDaysValue) => {
  const reolveDateFrom = () => {
    if (numberOfDaysValue === 'all-time') {
      return '2022-01-01';
    } else if (numberOfDaysValue === 'year-to-date') {
      return `${new Date().getFullYear()}-01-01`;
    } else {
      return moment().subtract(Number(numberOfDaysValue), 'd').format('YYYY-MM-DD');
    }
  };

  const endDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
  const dateFrom = reolveDateFrom();
  return { startDate: dateFrom, endDate };
};

export const returnChartData = (data = [], chartTitle = '') => {
  return {
    title: {
      text: chartTitle,
    },
    xAxis: {
      categories: data.map((t) => moment(t.date).format('MM/DD')).reverse(),
      labels: {
        angle: 90,
      },
      visible: true,
      opposite: false,
    },
    yAxis: [
      {
        title: { text: 'Users' },
      },
    ],
    series: [
      {
        showInLegend: false,
        showInNavigator: true,
        name: chartTitle,
        color: '#093d20',
        type: 'spline',
        data: data.map((au) => au.users).reverse(),
      },
    ],
  };
};

export const sortAnalyticDataByDate = (data = []) => {
  return data.sort((a, b) => {
    const ac = a?.date ? moment(a?.date) : moment('1980-01-01T12:00:00.000Z');
    const bc = b?.date ? moment(b?.date) : moment('1980-01-01T12:00:00.000Z');
    return bc.isAfter(ac) ? 1 : -1;
  });
};

export const sortAnalyticDataByUsers = (data = []) => {
  return data?.sort((a, b) => (Number(a.users) < Number(b.users) ? 1 : -1));
};

export function convertSecondsToHms(arg) {
  const d = Number(arg);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hours = h.toString().length === 1 ? `0${h}` : h;
  const minutes = m.toString().length === 1 ? `0${m}` : m;
  const seconds = s.toString().length === 1 ? `0${s}` : s;

  return `${hours}:${minutes}:${seconds}`;
}

export const convertToUSNumberFormat = (value = 0, shouldRound = false) => {
  let config;
  const nf = new Intl.NumberFormat('en-US', config);
  let valueParsed = nf.format(value);
  if (shouldRound && value?.length > 3) {
    valueParsed = `${valueParsed.substring(0, 3).replace(',', '.')}k`;
  }
  return valueParsed;
};
