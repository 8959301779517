import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

const NotFoundPage = () => {
  return (
    <div className="not-found-wrapper">
      <div className="m-auto">
        <Container fluid>
          <Row>
            <Col lg={12} className="not-found-block">
              <h1 className="text-white">404</h1>
              <h2>page not found</h2>
              <p className="text-white mb-5">
                The link you clicked may be broken or the page may have been removed.
              </p>
              <Link to="/" className="btn btn-white">
                Home
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default NotFoundPage;
