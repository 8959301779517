import { logOutUserAuthAction } from '../store/actions/authActions';
import { NavLink } from 'react-router-dom';
import { Nav, NavbarBrand, Navbar } from 'react-bootstrap';
import cross from '../assets/img/cross.png';
import dashboardIcon from '../assets/icons/layout-outline.svg';
import logoutIcon from '../assets/icons/log-out-outline.svg';
import calendarIcon from '../assets/icons/calendar.svg';
import tasksIcon from '../assets/icons/checkbox.svg';
import messageIcon from '../assets/icons/message.svg';
import sidebarIcon from '../assets/icons/sidebar.svg';
import videoIcon from '../assets/icons/video.svg';
import broadcastIcon from '../assets/icons/broadcast.svg';
import partnersIcon from '../assets/icons/users.svg';
import analyticsIcon from '../assets/icons/analytics.svg';
import { useAppDispatch } from 'index';

interface IProps {
  toggleSidebar: () => void;
  logo: {
    imgAlt: string;
    imgSrc: string;
    innerLink: string;
  };
}

const Sidebar = ({ toggleSidebar, logo }: IProps) => {
  const dispatch = useAppDispatch();
  const logout = async () => {
    dispatch(logOutUserAuthAction());
  };
  return (
    <Navbar className="navbar-content">
      <div className="scrollbar-inner">
        <div className="sidenav-header">
          {logo ? (
            <NavbarBrand>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
                width="150px"
              />
            </NavbarBrand>
          ) : null}
          <i className="close-menu-btn" onClick={toggleSidebar}>
            <img width="16" src={cross} alt="close" />
          </i>
        </div>
        <div className="navbar-inner">
          <Nav className="flex-column">
            <Nav.Item>
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img
                  className="me-1 mb-1"
                  width="20"
                  src={broadcastIcon}
                  alt="broadcast"
                />
                Broadcast
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/calendar"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={calendarIcon} alt="calendar" />
                Calendar
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/documents"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img
                  className="me-1 mb-1"
                  width="20"
                  src={dashboardIcon}
                  alt="calendar"
                />
                Document Library
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/sidebar"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={sidebarIcon} alt="sidebar" />
                External Links
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/messages"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={messageIcon} alt="messages" />
                Messages
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/partners"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={partnersIcon} alt="sidebar" />
                Partners
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/tasks"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={tasksIcon} alt="tasks" />
                Tasks
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/users"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={partnersIcon} alt="tasks" />
                Users
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/video-gallery"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="20" src={videoIcon} alt="sidebar" />
                Video Gallery
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                exact
                to="/analytics"
                className="nav-link"
                activeClassName="active-nav-link"
              >
                <img className="me-1 mb-1" width="18" src={analyticsIcon} alt="sidebar" />
                Analytics
              </NavLink>
            </Nav.Item>
          </Nav>
        </div>
        <div className="navbar-bottom">
          <Nav className="flex-column">
            <Nav.Item onClick={logout}>
              <div className="nav-link pointer">
                <img className="me-1 mb-1" width="20" src={logoutIcon} alt="logout" />
                Logout
              </div>
            </Nav.Item>
          </Nav>
        </div>
      </div>
    </Navbar>
  );
};

export default Sidebar;
