export const NUMBER_OF_ITEMS_PER_TABLE_PAGE_ARRAY = [10, 20, 50, 100, 200];

export const TASKS_TABLE_HEADERS = [
  {
    title: 'Description',
    value: 'description',
  },
  {
    title: 'Created At',
    value: 'createdAt',
  },
  {
    title: 'Updated At',
    value: 'updatedAt',
  },
  {
    title: 'Due Date',
    value: 'dueDate',
  },
  {
    title: 'Category',
    value: 'category',
  },
  {
    title: 'Members',
    value: 'entities',
  },
  {
    title: 'Notify',
    value: 'notify',
  },
  {
    title: 'Actions',
    value: null,
  },
];

export const EVENTS_TABLE_HEADERS = [
  {
    title: 'Event',
    value: 'event',
  },
  {
    title: 'Description',
    value: 'description',
  },
  {
    title: 'Created At',
    value: 'createdAt',
  },
  {
    title: 'Updated At',
    value: 'updatedAt',
  },
  {
    title: 'Date',
    value: 'date',
  },
  {
    title: 'Members',
    value: 'entities',
  },
  {
    title: 'Notify',
    value: 'notify',
  },
  {
    title: 'Actions',
    value: null,
  },
];

export const MESSAGES_TABLE_HEADERS = [
  {
    title: 'Subject',
    value: 'subject',
  },
  {
    title: 'Body',
    value: 'body',
  },
  {
    title: 'Created At',
    value: 'createdAt',
  },
  {
    title: 'Updated At',
    value: 'updatedAt',
  },
  {
    // title: 'Members/O.Groups',
    title: 'members_og',
    // value: 'entities',
    value: null,
  },
  {
    title: 'Notify',
    value: 'notify',
  },
  {
    title: 'Actions',
    value: null,
  },
];

export const USERS_TABLE_HEADERS = [
  {
    title: 'Primary Email',
    value: 'primary_email',
  },
  {
    title: 'First Name',
    value: 'first_name',
  },
  {
    title: 'Last Name',
    value: 'last_name',
  },
  {
    title: 'Member Status',
    value: 'member_status',
  },
  {
    title: 'Member Status Reason',
    value: 'member_status_reason',
  },
  {
    title: 'Tier',
    value: 'tier',
  },
  {
    title: 'Created',
    value: 'createdAt',
  },
  {
    title: 'Last login date',
    value: 'last_login',
  },
  {
    title: 'Reference ID',
    value: 'id',
  },
  {
    title: 'School Name',
    value: 'school_name',
  },
  {
    title: 'Membership',
    value: null,
  },
  {
    title: 'Actions',
    value: null,
  },
];

export const TASK_CATEGORIES = [
  {
    label: 'Access (log-in, claim account, enroll)',
    value: 'Access',
    id: 'Access',
  },
  {
    label: 'Conferences (register, pay conference invoice)',
    value: 'Conferences',
    id: 'Conferences',
  },
  {
    label: 'Finances (review invoice, pay invoice)',
    value: 'Finances',
    id: 'Finances',
  },
  {
    label: 'Learning or Training (summits, trainings, webinars)',
    value: 'Learning or Training',
    id: 'Learning or Training',
  },
  {
    label:
      'Health & Wellness (stay safe, homecoming, tailgating, halloween, policy updates, policy reminders)',
    value: 'Health & Wellness',
    id: 'Health & Wellness',
  },
  {
    label: 'Celebrations (graduations, initiations, associations, charterings, holidays)',
    value: 'Celebrations',
    id: 'Celebrations',
  },
  {
    label: 'Reminders (misc tasks)',
    value: 'Reminders',
    id: 'Reminders',
  },
];

export const ANALYTICS_PERIOD = [
  {
    label: 'Last 7 Days',
    value: '7',
    id: '7',
  },
  {
    label: 'Last 14 Days',
    value: '14',
    id: '14',
  },
  {
    label: 'Last Month',
    value: '30',
    id: '30',
  },
  {
    label: 'Last 3 Months',
    value: '90',
    id: '90',
  },
  {
    label: 'Last 6 Months',
    value: '180',
    id: '180',
  },
  {
    label: 'Year To Date',
    value: 'year-to-date',
    id: 'year-to-date',
  },
  {
    label: 'All Time',
    value: 'all-time',
    id: 'all-time',
  },
];

export const CHAPTER_OFFICE_ENTITIES = [
  {
    label: 'High Alpha',
    value: 'High Alpha',
    id: 'A-high-alpha',
  },
  {
    label: 'High Beta',
    value: 'High Beta',
    id: 'B-high-beta',
  },
  {
    label: 'High Theta',
    value: 'High Theta',
    id: 'C-high-theta',
  },
  {
    label: 'High Gamma',
    value: 'High Gamma',
    id: 'D-high-gamma',
  },
  {
    label: 'High Tau',
    value: 'High Tau',
    id: 'E-high-tau',
  },
  {
    label: 'High Iota',
    value: 'High Iota',
    id: 'F-high-iota',
  },
  {
    label: 'High Rho',
    value: 'High Rho',
    id: 'G-high-rho',
  },
  {
    label: 'High Kappa',
    value: 'High Kappa',
    id: 'H-high-kappa',
  },
  {
    label: 'High Delta',
    value: 'High Delta',
    id: 'I-high-delta',
  },
  {
    label: 'High Phi',
    value: 'High Phi',
    id: 'J-high-phi',
  },
  {
    label: 'High Sigma',
    value: 'High Sigma',
    id: 'K-high-sigma',
  },
  {
    label: 'High Epsilon',
    value: 'High Epsilon',
    id: 'L-high-epsilon',
  },
  {
    label: 'High Pi',
    value: 'High Pi',
    id: 'M-high-pi',
  },
  {
    label: 'AAB Member',
    value: 'AAB Membe',
    id: 'N-aab-member',
  },
  {
    label: 'ACB Chairman',
    value: 'ACB Chairman',
    id: 'O-acb-chairman',
  },
  {
    label: 'ACB Member',
    value: 'ACB Member',
    id: 'P-acb-member',
  },
];
