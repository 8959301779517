import React from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import Nav from './Nav';
import Sidebar from './Sidebar';
import logoImg from '../assets/img/logo.png';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../store/selectors/selectors';
import { useHistory } from 'react-router-dom';
import { IReduxStoreState } from 'lib/interfaces';

interface IProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: IProps) => {
  const [toggleSideNav, setToggleSideNav] = React.useState(false);
  const size = useWindowSize();
  const history = useHistory();
  const loggedUser = useSelector((state: IReduxStoreState) => getUserSelector(state));

  React.useEffect(() => {
    if (!loggedUser) {
      history.push('/login');
    }
  }, [history, loggedUser]);

  React.useEffect(() => {
    if (size.width && size.width < 876) {
      setToggleSideNav(true);
    } else {
      setToggleSideNav(false);
    }
  }, [size]);

  const toggleSidebar = () => {
    // TODO - fix adding class to hamburger menu
    // document.querySelector('#menu-trigger')?.classList.toggle('menu-clicked');
    setToggleSideNav(!toggleSideNav);
  };
  return (
    <>
      <div
        id="side"
        className={`side-menu-container ${toggleSideNav ? 'side-menu-close' : ''}`}
      >
        <Sidebar
          toggleSidebar={toggleSidebar}
          logo={{
            innerLink: '/',
            imgSrc: logoImg,
            imgAlt: '...',
          }}
        />
      </div>
      <div
        id="main"
        className={`page-content ${toggleSideNav ? 'page-content-move' : ''}`}
      >
        <Nav toggleSidebar={toggleSidebar} />
        {children}
      </div>
    </>
  );
};

export default DefaultLayout;
