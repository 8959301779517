import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { getAccessTokenAuth0 } from './auth0/auth0Auth';
import AuthorizationPage from './pages/auth-pages/AuthorizationPage';
import NotFoundPage from './pages/utils-pages/NotFoundPage';
import LoadingPage from 'pages/utils-pages/LoadingPage';
import PrivateRoute from 'components/Routes/PrivateRoute';
import {
  // AnalyticsPageLazy,
  BroadcastPageLazy,
  CalendarPageLazy,
  DocumentLibraryPageLazy,
  LoginPageLazy,
  MessagesPageLazy,
  PartnersPageLazy,
  SidebarPageLazy,
  TasksPageLazy,
  UsersPageLazy,
  VideoGalleryPageLazy,
  AddVideoPageLazy,
  EditVideoPageLazy,
  CreateCalendarEventPageLazy,
  EditCalendarEventPageLazy,
  CalendarEventDetailsPageLazy,
  CreateMessagePageLazy,
  CreatePartnerPageLazy,
  CreateTaskPageLazy,
  EditMessagePageLazy,
  EditPartnerPageLazy,
  EditTaskPageLazy,
  MessageDetailsPageLazy,
  PartnerDetailsPageLazy,
  TaskDetailsPageLazy,
  MembershipAgreementsPageLazy,
} from 'pages';
import AnalyticsPage from 'pages/analytics-pages/AnalyticsPage';

import './styles/globals.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

// * Axios Settings & Interceptors
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use(
  async (config) => {
    const token = await getAccessTokenAuth0();
    if (token) {
      if (config.headers !== undefined) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login?authStatus=SessionExpired';
    } else if (
      error.response.data.statusCode === 401
      && error.response.data.message.includes('jwt')
    ) {
      window.location.href = '/login?authStatus=TokenInvalid';
    }
    return Promise.reject(error);
  },
);

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route path="/login" exact component={LoginPageLazy} />
            <Route path="/authorization" component={AuthorizationPage} />
            <PrivateRoute path="/" exact component={BroadcastPageLazy} />
            <PrivateRoute path="/add-video" component={AddVideoPageLazy} />
            <PrivateRoute path="/calendar" exact component={CalendarPageLazy} />
            <PrivateRoute path="/create-event" component={CreateCalendarEventPageLazy} />
            <PrivateRoute
              path="/create-message"
              exact
              component={CreateMessagePageLazy}
            />
            <PrivateRoute path="/create-partner" component={CreatePartnerPageLazy} />
            <PrivateRoute path="/create-task" component={CreateTaskPageLazy} />
            <PrivateRoute path="/documents" component={DocumentLibraryPageLazy} />
            <PrivateRoute path="/edit-event/:id" component={EditCalendarEventPageLazy} />
            <PrivateRoute path="/edit-message/:id" component={EditMessagePageLazy} />
            <PrivateRoute path="/edit-partner/:id" component={EditPartnerPageLazy} />
            <PrivateRoute path="/edit-task/:id" component={EditTaskPageLazy} />
            <PrivateRoute path="/edit-video/:id" component={EditVideoPageLazy} />
            <PrivateRoute path="/event/:id" component={CalendarEventDetailsPageLazy} />
            <PrivateRoute path="/messages" exact component={MessagesPageLazy} />
            <PrivateRoute path="/message/:id" component={MessageDetailsPageLazy} />
            <PrivateRoute path="/partners" exact component={PartnersPageLazy} />
            <PrivateRoute path="/partner/:id" component={PartnerDetailsPageLazy} />
            <PrivateRoute path="/sidebar" exact component={SidebarPageLazy} />
            <PrivateRoute path="/tasks" exact component={TasksPageLazy} />
            <PrivateRoute path="/task/:id" component={TaskDetailsPageLazy} />
            <PrivateRoute path="/users" component={UsersPageLazy} />
            <PrivateRoute path="/video-gallery" exact component={VideoGalleryPageLazy} />
            <PrivateRoute path="/analytics" exact component={AnalyticsPage} />
            <PrivateRoute
              path="/membership-agreements"
              exact
              component={MembershipAgreementsPageLazy}
            />
            <Route path="" component={NotFoundPage} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
