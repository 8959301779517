import { Col, Spinner } from 'react-bootstrap';

interface IProps {
  cardNumber: number | string;
  cardText: string;
  loaderActive: boolean;
}

const SummaryComponent = ({
  cardNumber = 0,
  cardText = '',
  loaderActive = false,
}: IProps) => {
  return (
    <Col
      className="d-flex flex-column align-items-center justify-content-center border rounded px-4 py-3 me-2"
      style={{ minHeight: '170px' }}
    >
      {loaderActive ? (
        <Spinner animation="grow" />
      ) : (
        <>
          <p className="display-5">{cardNumber}</p>
          <div className="text-uppercase small fw-lighter">{cardText}</div>
        </>
      )}
    </Col>
  );
};

export default SummaryComponent;
