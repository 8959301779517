const TableSkeleton = ({ numberOfHeaders = 0, numberOfRows = 0 }) => {
  const tableHeaders = Array.from(Array(numberOfHeaders).keys());
  const tableRows = Array.from(Array(numberOfRows).keys());

  return (
    <tbody>
      {tableRows?.map((tr) => (
        <tr key={tr}>
          {tableHeaders?.map((td) => (
            <td key={td} className="loading">
              <div className="bar" />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableSkeleton;
