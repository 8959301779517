import { IUserQuery } from 'lib/interfaces';
import errorHandler from '../../utils/errorHandler';
import {
 deleteUser, getAllUsers, downloadUsers, getAnalytics,
} from '../apiCalls';

export const getAllUsersUsersAction = (
  page: number,
  limit: number,
  firstName: string,
  lastName: string,
  email: string,
  sortDirection: string,
  sortBy: string,
) => {
  return async () => {
    try {
      // const { firstName = '', lastName = '', email = '' } = searchQuery;
      const res = await getAllUsers(
        page,
        limit,
        firstName,
        lastName,
        email,
        sortDirection,
        sortBy,
      );
      if (res) {
        return res;
      } else return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const downloadUsersUsersAction = (downloadQuery: IUserQuery) => {
  return async () => {
    const { firstName = '', lastName = '', email = '' } = downloadQuery;
    try {
      const res = await downloadUsers(firstName, lastName, email);
      if (res) {
        return res;
      } else return [];
    } catch (error) {
      errorHandler(error);
      return [];
    }
  };
};

export const deleteUserUsersActions = (id = '') => {
  return async () => {
    try {
      const res = await deleteUser(id);
      if (res) {
        return res;
      } else return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getAnalyticsUsersAction = (startDate: string, endDate: string) => {
  return async () => {
    try {
      const res = await getAnalytics(startDate, endDate);
      if (res) {
        return res;
      } else return false;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};
