import { AppDispatch } from 'index';
import { loginAdminAuth0, logoutAdminAuth0 } from '../../auth0/auth0Auth';
import errorHandler from '../../utils/errorHandler';
import { getAdmin } from '../apiCalls';

export const logInUserAuthAction = () => {
  return async () => {
    try {
      const user = await loginAdminAuth0();
      if (user) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  };
};

export const logOutUserAuthAction = (status?: string | undefined) => {
  return async (dispatch: AppDispatch) => {
    try {
      await logoutAdminAuth0(status);
      dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });
      return true;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };
};

export const getAdminAuthAction = () => {
  return async (dispatch: AppDispatch) => {
    const admin = await getAdmin();
    if (admin) {
      dispatch({ type: 'LOGIN_SUCCESS', user: admin });
      return true;
    } else return false;
  };
};
