import { Component } from 'react';
// import errorImage from '../../assets/img/error-images/error_image.svg';

const THREAD_IDS = {
  dev: '1035530917151789128',
  stage: '1035530976132079708',
  prod: '1035531042007822476',
};

export default class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      // error: {}
    };
  }

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.log('ErrorBoundaryComponent getDerivedStateFromError ->', { error });
    return { hasError: true, error };
  }

  async componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log('ErrorBoundaryComponent componentDidCatch ->', { error, errorInfo });
    // this.setState({ error });
    const isNotLocalHost = window.location.hostname !== 'localhost';
    const URL = `https://discord.com/api/webhooks/1035532597511606312/tQTa0hJLtI9fwm9_dfTi1WhpKxyv7MJmb4llZJlwRl1WKwhSLj1FzAM8r5kkco8aABwV?thread_id=${
      THREAD_IDS[process.env.REACT_APP_ENVIRONMENT]
    }`;
    // if we're not on localhost and error stack is defined send the error to the server
    if (isNotLocalHost && error?.stack) {
      const data = JSON.stringify(
        {
          app: 'LambdaOnline Admin Dashboard',
          timeStamp: new Date().toISOString(),
          agent: navigator.userAgent,
          path: window.location.href,
          fileInfo: {
            fileName: error?.fileName,
            col: error?.columnNumber,
            line: error?.lineNumber,
          },
          message: error?.message,
          stack: errorInfo?.componentStack,
          fullStack: `${error?.stack?.substring(0, 500)}...`,
        },
        null,
        2,
      );
      const discFormatedMsg = `\`\`\`json\n${data}\`\`\``;
      fetch(URL, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          content: discFormatedMsg,
        }),
      });
    }
  }

  render() {
    const { hasError = false } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="error-boundary-component-wrapper">
          {/* <img src={errorImage} alt="error graphic" /> */}
          <h1>Oops!</h1>
          <h2>Something went wrong...</h2>
          <p>We are sorry for the inconvenience, try to refresh the page.</p>
          {/* {message ? (
            <div className="component-stack">
              Error Message:
              {' '}
              <span>{message}</span>
            </div>
          ) : (
            <></>
          )} */}
          <div className="error-boundary-component-btns">
            <div
              className="error-boundary-component-btn"
              onClick={() => {
                window.location.reload();
              }}
            >
              Click here to refresh the page manually
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}
