interface INavProps {
  toggleSidebar: () => void;
}

const Nav = (props: INavProps) => {
  const { toggleSidebar } = props;

  return (
    <nav className="nav-custom d-flex justify-content-end mb-2">
      <div className="d-flex align-items-center">
        <div id="menu-trigger" onClick={toggleSidebar}>
          <span className="menu-stripe stripe-top me-3" />
        </div>
      </div>
    </nav>
  );
};

export default Nav;
