import axios from 'axios';
import {
  IFileObj,
  IFolderDocumentLibraryAdd,
  IFolderDocumentLibraryDelete,
  IFolderDocumentLibraryEdit,
  INavbarExternalLink,
  IPartner,
  ISidebarExternalLink,
  IVideoGalleryData,
} from 'lib/interfaces';
import { ALERT_MESSAGES } from '../utils/alerts';
import errorHandler from '../utils/errorHandler';
import { swalError } from '../utils/toasts';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

export const getAdmin = async () => {
  try {
    const { data = false } = await axios.get(`${baseUrl}/admin`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getMessagesAdmin = async (
  page = 1,
  limit = 1000,
  sortDirection = 'DESC',
  sortBy = 'createdAt',
) => {
  try {
    const { data = {} } = await axios.get(
      `${baseUrl}/message/admin?page=${page}&limit=${limit}&sortDirection=${sortDirection}&sortBy=${sortBy}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleMessageAdmin = async (messageId = '') => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/message/admin/${messageId}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const createAMessage = async (newMessageData = {}) => {
  try {
    const { data = {} } = await axios.post(`${baseUrl}/message`, newMessageData);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateMessage = async (messageId = '', updatedMessage = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/message/${messageId}`, updatedMessage);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteMessage = async (messageId = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/message/${messageId}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getCalendarEvents = async (
  page = 1,
  limit = 100,
  sortDirection = 'DESC',
  sortBy = 'createdAt',
) => {
  try {
    const { data = {} } = await axios.get(
      `${baseUrl}/event/admin?page=${page}&limit=${limit}&sortDirection=${sortDirection}&sortBy=${sortBy}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleCalendarEvent = async (eventId = '') => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/event/admin/${eventId}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const createCalendarEvent = async (newEventData = {}) => {
  try {
    const { data = {} } = await axios.post(`${baseUrl}/event`, newEventData);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateCalendarEvent = async (eventId = '', updatedEvent = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/event/${eventId}`, updatedEvent);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteCalendarEvent = async (eventId = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/event/${eventId}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getTasksAdmin = async (
  page = 1,
  limit = 100,
  sortDirection = 'DESC',
  sortBy = 'createdAt',
) => {
  try {
    const { data = {} } = await axios.get(
      `${baseUrl}/task/admin?page=${page}&limit=${limit}&sortDirection=${sortDirection}&sortBy=${sortBy}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getMembershipAgreements = async (page = 1, limit = 10) => {
  try {
    const { data = '' } = await axios.get(
      `${baseUrl}/membership/admin?page=${page}&limit=${limit}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleTaskAdmin = async (taskId = '') => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/task/admin/${taskId}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const createATask = async (newTaskData = {}) => {
  try {
    const { data = {} } = await axios.post(`${baseUrl}/task`, newTaskData);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateTask = async (taskId = '', updatedTask = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/task/${taskId}`, updatedTask);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteTask = async (taskId = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/task/${taskId}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSidebarLinks = async () => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/sidebar/admin`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateSidebar = async (links = [] as ISidebarExternalLink[]) => {
  try {
    const { data = [] } = await axios.patch(`${baseUrl}/sidebar`, links);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getNavbarLinks = async () => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/navbar/admin`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateNavbar = async (links = [] as Array<INavbarExternalLink>) => {
  try {
    const { data = [] } = await axios.patch(`${baseUrl}/navbar`, links);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllVideos = async () => {
  try {
    const res = await axios.get(`${baseUrl}/gallery`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleVideoAdmin = async (videoId = '') => {
  try {
    const res = await axios.get(`${baseUrl}/gallery/${videoId}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const createVideoAdmin = async (newVideoData = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/gallery`, newVideoData);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateVideo = async (videoId = '', updatedVideo = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/gallery/${videoId}`, updatedVideo);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteSingleVideoAdmin = async (videoId = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/gallery/${videoId}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPartners = async () => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/partner`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSinglePartner = async (id = '') => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/partner/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addPartner = async (partner = {}) => {
  try {
    const response = await axios.post(`${baseUrl}/partner`, partner);
    return response;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updatePartner = async (id = '', partner = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/partner/${id}`, partner);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deletePartner = async (id = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/partner/${id}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const reorderPartners = async (partners = [] as IPartner[]) => {
  try {
    const { data = {} } = await axios.patch(`${baseUrl}/partner`, partners);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const reorderGallery = async (reorderedGallery = [] as IVideoGalleryData[]) => {
  try {
    const { data = [] } = await axios.patch(`${baseUrl}/gallery`, reorderedGallery);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getFolderDocumentLibrary = async (path = '' as string | null) => {
  try {
    if (path === '' || path === null) {
      const { data = {} } = await axios.get(`${baseUrl}/document/admin`);
      return data;
    } else {
      const { data = {} } = await axios.get(`${baseUrl}/document/admin?path=${path}`);
      return data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addFolderDocumentLibrary = async (
  newFolderData: IFolderDocumentLibraryAdd,
) => {
  try {
    const res = await axios.post(`${baseUrl}/document/folder`, newFolderData);
    return res;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const { message = '' } = error;
    if (message.includes('400')) {
      swalError(ALERT_MESSAGES.THIS_TITLE_IS_ALREADY_TAKEN_MESSAGE);
    } else {
      errorHandler(error);
    }
    return false;
  }
};

export const editFolderNameDocumentLibrary = async (
  editedFolderNameData: IFolderDocumentLibraryEdit,
) => {
  try {
    const res = await axios.patch(
      `${baseUrl}/document/folder/rename`,
      editedFolderNameData,
    );
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleFile = async (id = '') => {
  try {
    const { data = {} } = await axios.get(`${baseUrl}/document/admin/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addFileDocument = async (file = {}) => {
  try {
    const res = await axios.post(`${baseUrl}/document`, file);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateFileDocument = async (id = '', file = {}) => {
  try {
    const res = await axios.patch(`${baseUrl}/document/${id}`, file);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteFileDocument = async (id = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/document/${id}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteFolderDocumentLibrary = async (
  deleteFolderObject: IFolderDocumentLibraryDelete,
) => {
  const { title, path } = deleteFolderObject;
  try {
    const res = await axios.delete(`${baseUrl}/document/folder/delete`, {
      data: { title, path },
    });
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleFileDownloadURL = async (id = '') => {
  try {
    const { data = '' } = await axios.get(`${baseUrl}/document/admin/download/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllUsers = async (
  page = 1,
  limit = 10,
  firstName = '',
  lastName = '',
  email = '',
  sortDirection = 'DESC',
  sortBy = 'createdAt',
) => {
  const queryObject = {
    page: page.toString(),
    limit: limit.toString(),
    first_name: firstName.trim().toLowerCase(),
    last_name: lastName.trim().toLowerCase(),
    email: email.trim().toLowerCase(),
    sortDirection,
    sortBy,
  };
  try {
    const searchParams = new URLSearchParams(queryObject);
    const { data = '' } = await axios.get(
      `${baseUrl}/user/admin?${searchParams.toString()}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const downloadUsers = async (firstName = '', lastName = '', email = '') => {
  const searchParams = new URLSearchParams({
    first_name: firstName.trim().toLowerCase(),
    last_name: lastName.trim().toLowerCase(),
    email: email.trim().toLowerCase(),
  });
  const url = `${baseUrl}/user/admin/export?${searchParams.toString()}`;
  try {
    const { data = '' } = await axios.get(url);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteUser = async (id = '') => {
  try {
    const res = await axios.delete(`${baseUrl}/user/${id}`);
    return res;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = async (fileObj: IFileObj | any) => {
  const formData = new FormData();
  Object.keys(fileObj).forEach((key) => {
    formData.append(key, fileObj[key]);
  });
  try {
    const { data } = await axios.post(`${baseUrl}/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSingleMembershipAgreement = async (url = '') => {
  try {
    const { data = '' } = await axios.get(`${baseUrl}/upload`, { params: { url } });
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const revokeMembershipAgreement = async (id = '') => {
  try {
    const { data = '' } = await axios.patch(`${baseUrl}/membership/admin/revoke/${id}`);
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAnalytics = async (startDate: string, endDate: string) => {
  try {
    const { data = {} } = await axios.get(
      `${baseUrl}/user/admin/analytics?start_date=${startDate}&end_date=${endDate}`,
    );
    return data;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
