import { IReduxStoreState } from 'lib/interfaces';

export const getUserSelector = (state: IReduxStoreState) => {
  const {
    user: { user = false },
  } = state;
  return user || false;
};

// export const getUserIDSelector = (state: any): string => {
//   const {
//     user: { user: { _id: userID = '' } = {} },
//   } = state;
//   return userID || '';
// };
